<template>
  <header
      class="flex justify-between items-center py-4 px-6 bg-white border-b-4 border-gray-600"
  >
    <div class="flex items-center">
      <button
          @click="$env.sidebarIsOpen = true"
          class="text-gray-500 focus:outline-none lg:hidden"
      >
        <svg
            class="h-6 w-6"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
          <path
              d="M4 6H20M4 12H20M4 18H11"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
          />
        </svg>
      </button>

      <div class="relative mx-4 lg:mx-0" v-if="search.includes($route.name)">
        <span class="absolute inset-y-0 left-0 pl-3 flex items-center">
          <i class="fal fa-search"></i>
        </span>

        <input
            class="form-input w-32 sm:w-64 rounded-md pl-10 py-1 pr-4 focus:border-indigo-600"
            type="text"
            placeholder="Suchen"
            v-model="$env.searchField"
        />
      </div>
    </div>

    <div class="flex items-center">
      <div class="relative hidden">
        <button
            @click="() => {notificationsOpen = !notificationsOpen;dropdownOpen = false}"
            class="relative z-10 block h-10 w-10 text-lg text-gray-600 rounded-full overflow-hidden focus:outline-none"
        >
          <i class="fal fa-bell"></i>
        </button>

        <div
            v-show="notificationsOpen"
            @click="notificationsOpen = false"
            class="fixed inset-0 h-full w-full z-10"
        ></div>

        <div
            v-show="notificationsOpen"
            class="absolute right-0 mt-2 py-2 w-48 bg-white rounded-md shadow-xl z-20 border"
        >
          <span
              class="block px-4 py-2 text-sm text-gray-700"
          >Keine Benachrichtigungen</span
          >
        </div>
      </div>
      <div class="relative">
        <button
            @click="() => {dropdownOpen = !dropdownOpen;notificationsOpen = false}"
            class="relative z-10 block h-10 w-10 rounded-full overflow-hidden shadow focus:outline-none"
        >
          <img
              class="h-full w-full object-cover"
              :src="'https://live.sendnode.com/user/Ownsetting/getAvatar/128?sid='+ $store.getSID()"
              alt="Your avatar"
          />
        </button>

        <div
            v-show="dropdownOpen"
            @click="dropdownOpen = false"
            class="fixed inset-0 h-full w-full z-10"
        ></div>

        <div
            v-show="dropdownOpen"
            class="absolute right-0 mt-2 py-2 w-48 bg-white rounded-md shadow-xl z-20 border"
        >
          <router-link
              to="/"
              @click="$store.logout()"
              class="block px-4 py-2 text-sm text-gray-700 hover:bg-primary hover:text-white">Log out</router-link>
          <a
              @click="$store.refreshToken()"
              class="block px-4 py-2 text-sm text-gray-700 hover:bg-primary hover:text-white cursor-pointer">Refresh Session</a>
        </div>
      </div>
    </div>
  </header>
</template>


<script>
export default {
  name: 'Header',
  data() {
    return {
      dropdownOpen: false,
      notificationsOpen: false,
      search: [
          'AdminEventAttendees'
      ]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
