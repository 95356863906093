<template>
  <Login :transparent="loginTransparent" v-if="showLogin === true" />
  <div class="flex h-screen bg-gray-200 font-roboto" v-if="showUi === true">
    <Sidebar />

    <div class="flex-1 flex flex-col overflow-hidden">
      <Header />

      <main class="flex-1 overflow-x-hidden overflow-y-auto bg-gray-200 relative overflow-y-scroll">
        <div class="container mx-auto px-6 py-8">
          <router-view />
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import Sidebar from "@/views/Admin/Sidebar";
import Header from "@/views/Admin/Header";
import Login from "@/components/Admin/Login";

export default {
  components: {
    Sidebar,
    Header,
    Login
  },
  data(){
    return {
      showLogin: false,
      loginTransparent: true
    }
  },
  beforeCreate() {
    if(this.$store.authorized() === false) this.$router.push('/')
    this.$store.uiToast = this.$uiToast
  },
  computed: {
    showUi(){
      if(this.showLogin === true && this.loginTransparent === true) return true
      if(this.showLogin === true && this.loginTransparent !== true) return false
      return true
    }
  }
}
</script>
